import React from 'react';
import MissionBlock from './components/about-us/mission-block';
import BusinessDescriptionBlock from './components/about-us/business-description';
import CallToActionsBlock from './components/about-us/call-to-actions-block';
import TimelineBlock from './components/about-us/timeline';

const AboutUsPageIndex = () => {
    return (
        <main className={'flex flex-col mt-12 justify-center overflow-x-hidden'}>
            <MissionBlock />
            <BusinessDescriptionBlock />
            <TimelineBlock />
            <CallToActionsBlock />
        </main>
    );
};

export default AboutUsPageIndex;
