import React from 'react';
import { css } from '@emotion/core';
import { Tab } from '@blueprintjs/core';
import BenefitTab from '../../common/benefit-tab';
import { Link, useTranslation } from '../../../../../utils/i18n/i18n';
import MaxWidthWrapper from '../../../../../components/common/wrappers/max-width-wrapper';
import { ProgressiveImage } from '../../../../../components/common';
import { Button, CustomTabs } from '@components/ui';

/**
 * The breakpoints are needed for faster, centralized and more reliable way to make responsive apps using emotion
 */
const BenefitsBlock = () => {
    const { t } = useTranslation();

    return (
        <MaxWidthWrapper color={'white'}>
            <article
                css={css`
                    display: flex;
                    padding-bottom: 5%;
                    background-color: white;
                    min-height: 37.5rem;
                `}>
                <CustomTabs
                    id={'business-benefits'}
                    large={false}
                    animate={true}
                    className={'mt-8'}
                    renderActiveTabPanelOnly={true}>
                    <Tab
                        id={'marketing'}
                        title={t(
                            'landing-page:for-partners.for-partners-page-sub-header-marketing'
                        )}
                        panel={
                            <BenefitTab
                                header={t(
                                    'landing-page:for-partners.for-partners-page-marketing-header'
                                )}
                                text={t(
                                    'landing-page:for-partners.for-partners-page-marketing-text-body'
                                )}
                                cta={
                                    <Link href={'/message'}>
                                        <Button
                                            large={true}
                                            text={t(
                                                'landing-page:for-partners.for-partners-page-marketing-button'
                                            )}
                                            intent={'primary'}
                                            onClick={() => console.log('test click')}
                                        />
                                    </Link>
                                }
                                illustration={
                                    <ProgressiveImage
                                        src={
                                            'https://res.cloudinary.com/coresine/image/upload/v1606946214/landing_page_static_files/for-partners/marketing.svg'
                                        }
                                        alt={
                                            'Shows marketing by showing parts of the UI responsible for it'
                                        }
                                        className={'ml-4 mt-8'}
                                        width={560}
                                        height={480}
                                    />
                                }
                            />
                        }
                    />
                    <Tab
                        id={'analytics'}
                        title={t(
                            'landing-page:for-partners.for-partners-page-sub-header-analytics'
                        )}
                        panel={
                            <BenefitTab
                                header={t(
                                    'landing-page:for-partners.for-partners-page-analytics-header'
                                )}
                                text={t(
                                    'landing-page:for-partners.for-partners-page-analytics-text-body'
                                )}
                                cta={
                                    <Link href={'/message'}>
                                        <Button
                                            large={true}
                                            text={t(
                                                'landing-page:for-partners.for-partners-page-analytics-button'
                                            )}
                                            intent={'primary'}
                                            onClick={() => console.log('test click')}
                                        />
                                    </Link>
                                }
                                illustration={
                                    <ProgressiveImage
                                        src={
                                            'https://res.cloudinary.com/coresine/image/upload/v1606946213/landing_page_static_files/for-partners/analytics.svg'
                                        }
                                        alt={
                                            'Shows analytics by showing parts of the UI responsible for it'
                                        }
                                        className={'ml-8 mt-8'}
                                        width={560}
                                        height={395}
                                    />
                                }
                            />
                        }
                    />
                </CustomTabs>
            </article>
        </MaxWidthWrapper>
    );
};

export default BenefitsBlock;
