import React from 'react';
import { css } from '@emotion/core';
import { FormGroup, InputGroup } from '@blueprintjs/core';
import { useForm } from 'react-hook-form';
import Theme from '../../../../../utils/theming/theme-type';
import { useTheme } from 'emotion-theming';
import { Image, Transformation } from 'cloudinary-react';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IContactFormFill } from '@services/contact-service/types';
import * as AsyncOperations from '../../../operations';
import { connect } from 'react-redux';
import { IProps } from '../contacts/form-block';
import MaxWidthWrapper from '../../../../../components/common/wrappers/max-width-wrapper';
import { useEffect, useState } from 'react';
import { ToasterShower } from '@components';
import { Button } from '@components/ui';
import { useTranslation } from '../../../../../utils/i18n/i18n';

const PromiseBlock: React.FunctionComponent<IProps> = ({ onFormSubmitPressed, form }) => {
    const theme: Theme = useTheme();
    const { register, handleSubmit } = useForm();
    const [isJoinButtonPressed, setIsJoinButtonPressed] = useState<boolean>(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (form?.submitted) {
            emailSubmissionSucceeded();
        }
        if (!form?.submitted && isJoinButtonPressed) {
            emailSubmissionFailed();
        }
    }, [form]);

    const emailSender = (email) => {
        const newData = {
            name: 'WAITLIST',
            ...email,
            phone: 'WAITLIST',
            text: 'WAITLIST'
        };
        console.log('newData: ', newData);
        setIsJoinButtonPressed(true);
        onFormSubmitPressed(newData);
    };

    const emailSubmissionSucceeded = () => {
        ToasterShower.show({
            message: t('landing-page:message.message-page-toaster-success'),
            intent: 'success'
        });
    };

    const emailSubmissionFailed = () => {
        ToasterShower.show({
            message: t('landing-page:message.message-page-toaster-failure'),
            intent: 'danger'
        });
    };

    return (
        <MaxWidthWrapper color={'white'}>
            <article
                css={css`
                    display: flex;
                    padding: 2rem 0;
                    height: 100%;
                    ${theme.layout.mq[1]} {
                        flex-direction: row;
                    }
                `}>
                <form
                    onSubmit={handleSubmit(emailSender)}
                    css={css`
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        p {
                            font-size: ${theme.fontSize.header};
                        }
                        label {
                            font-weight: bold;
                            font-size: ${theme.fontSize
                                .default}; // header is also a plausible option
                        }
                    `}>
                    <h1
                        css={css`
                            font-size: ${theme.fontSize.display};
                            color: ${theme.colors.secondary};
                        `}>
                        {t('landing-page:message.message-page-header')}
                    </h1>
                    <p>{t('landing-page:message.message-page-paragraph1')}</p>
                    <p>{t('landing-page:message.message-page-paragraph2')}</p>
                    <FormGroup
                        helperText={t('landing-page:message.message-page-input-helper-text')}
                        labelFor={'emailInput'}
                        label={t('landing-page:message.message-page-input-label')}
                        css={css`
                            margin-top: 1.5rem;
                        `}>
                        <InputGroup
                            type={'email'}
                            placeholder={t('landing-page:message.message-page-input-placeholder')}
                            name={'email'}
                            large={false}
                            inputRef={register({ required: true })}
                            id={'emailInput'}
                        />
                    </FormGroup>
                    <Button
                        text={
                            form?.submitted
                                ? t('landing-page:message.message-page-button-on-success')
                                : t('landing-page:message.message-page-button')
                        }
                        large={true}
                        type={'submit'}
                        loading={isJoinButtonPressed && !form?.submitted}
                        disabled={form?.submitted}
                        intent={form?.submitted ? 'success' : 'primary'}
                    />
                </form>
                <Image
                    cloudName={'coresine'}
                    secure={true}
                    publicId={'coming-soon'}
                    alt={
                        'The illustration that shows that the website is currently in beta and under construction'
                    }
                    css={css`
                        display: none;
                        ${theme.layout.mq[1]} {
                            display: block;
                            width: 140%;
                        }
                    `}>
                    <Transformation flags={'progressive:semi'} />
                </Image>
            </article>
        </MaxWidthWrapper>
    );
};

const mapStateToProps = (state) => {
    return {
        form: state.landingPageState.form
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        onFormSubmitPressed: (form: IContactFormFill) =>
            dispatch(AsyncOperations.submitContactFormAsync(form))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromiseBlock);
