import React from 'react';
import { css } from '@emotion/core';
import { Card } from '@blueprintjs/core';
import Theme from '../../../../../utils/theming/theme-type';
import { useTheme } from 'emotion-theming';
import { useTranslation } from '../../../../../utils/i18n/i18n';
import MaxWidthWrapper from '../../../../../components/common/wrappers/max-width-wrapper';
import parser from 'html-react-parser';

/**
 * The breakpoints are needed for faster, centralized and more reliable way to make responsive apps using emotion
 */
const PartnersBlock = () => {
    const theme: Theme = useTheme();
    const { t } = useTranslation();

    return (
        <MaxWidthWrapper color={'white'}>
            <article className={'flex flex-col bg-white'}>
                <section className={'flex flex-col mb-8'}>
                    <h2 className={'text-2xl'}>
                        {t(
                            'landing-page:for-partners.for-partners-page-partners-block-what-businesses-pay-for-header'
                        )}
                    </h2>
                    <p className={'text-base'}>
                        {t(
                            'landing-page:for-partners.for-partners-page-partners-block-what-businesses-pay-for-text-body'
                        )}
                    </p>
                    <p className={'text-base'}>
                        {t(
                            'landing-page:for-partners.for-partners-page-partners-block-what-businesses-pay-for-text-two-body'
                        )}
                    </p>
                </section>
                <div className={'flex flex-col'}>
                    <div className={'flex flex-col justify-between mb-8 sm:flex-row'}>
                        <Card className={'flex flex-col p-4'}>
                            <h1 className={'my-1 text-2xl'}>
                                {' '}
                                {t(
                                    'landing-page:for-partners.for-partners-page-partners-block-what-businesses-pay-for-core-header'
                                )}{' '}
                            </h1>
                            {parser(
                                t(
                                    'landing-page:for-partners.for-partners-page-partners-block-what-businesses-pay-for-core-text-body'
                                )
                            )}
                        </Card>
                        <Card
                            className={'flex flex-col p-4'}
                            css={css`
                                border: 4px solid ${theme.colors.primary};
                                background-color: ${theme.colors.greyPrimaryTint};
                            `}>
                            <h1 className={'my-1 text-2xl'}>
                                {' '}
                                {t(
                                    'landing-page:for-partners.for-partners-page-partners-block-what-businesses-pay-for-auxiliary-header'
                                )}{' '}
                            </h1>
                            {parser(
                                t(
                                    'landing-page:for-partners.for-partners-page-partners-block-what-businesses-pay-for-auxiliary-text-body'
                                )
                            )}
                        </Card>
                    </div>
                </div>
            </article>
        </MaxWidthWrapper>
    );
};

export default PartnersBlock;
