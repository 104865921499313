import React from 'react';
import { css } from '@emotion/core';
import Theme from '../../../../../utils/theming/theme-type';
import { useTheme } from 'emotion-theming';
import { useTranslation } from '../../../../../utils/i18n/i18n';
import Link from 'next/link';
import parser from 'html-react-parser';
import MaxWidthWrapper from '../../../../../components/common/wrappers/max-width-wrapper';
import { Intent } from '@blueprintjs/core';
import { useRouter } from 'next/router';
import { ProgressiveImage } from '../../../../../components/common';
import useMedia from 'use-media';
import { Button } from '@components/ui';

const WelcomingBlock: React.FunctionComponent = () => {
    const router = useRouter();

    const isMobile: boolean = useMedia({ minWidth: 640 });

    const theme: Theme = useTheme();
    const { t } = useTranslation();

    const onClickHandler = () => {
        router.push('/home');
    };

    return (
        <MaxWidthWrapper color={'white'}>
            <article
                className={'flex flex-col pb-8'}
                css={css`
                    padding-top: 5%;
                    ${theme.layout.mq[0]} {
                        min-height: 37.5rem;
                    }
                `}>
                <div
                    className={'flex items-center flex-col sm:flex-row'}
                    css={css`
                        ${theme.layout.mq[0]} {
                            flex-direction: row;
                        }
                    `}>
                    <div className={'flex flex-col justify-center leading-normal'}>
                        <h1 className={'text-4xl leading-10 text-black my-1'}>
                            {parser(
                                t('landing-page:landing-page.landing-page-welcome-block-header')
                            )}
                        </h1>
                        <p className={'text-2xl mt-4'}>
                            {t('landing-page:landing-page.landing-page-welcome-block-much-more')}
                        </p>
                        <Button
                            text={t('landing-page:landing-page.landing-page-home-lead-button')}
                            intent={Intent.PRIMARY}
                            onClick={onClickHandler}
                            large={true}
                            css={css`
                                align-self: flex-start;
                                margin-top: 0.5rem;
                            `}
                        />
                        <Link href={'/about-us'}>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className={'text-blue-500 mt-4 hover:text-blue-800 hover:underline'}>
                                {t(
                                    'landing-page:landing-page.landing-page-what-is-coresine-block-learn-more'
                                )}
                            </a>
                        </Link>
                    </div>
                    <div>
                        {isMobile && (
                            <ProgressiveImage
                                src={
                                    'https://res.cloudinary.com/coresine/image/upload/v1606946150/landing_page_static_files/index-page.svg'
                                }
                                alt={'UI prototype of the home page'}
                                className={'ml-8'}
                                width={560}
                                height={400}
                            />
                        )}
                    </div>
                </div>
            </article>
        </MaxWidthWrapper>
    );
};

export default WelcomingBlock;
