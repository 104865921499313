import React from 'react';
import { css } from '@emotion/core';
import StartButtons from './start-buttons';
import Theme from '../../../../../utils/theming/theme-type';
import { useTheme } from 'emotion-theming';
import { useTranslation } from '../../../../../utils/i18n/i18n';

const StartNowBlock: React.FunctionComponent<{}> = () => {
    const theme: Theme = useTheme();
    const { t } = useTranslation();

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 50vh;
                h1 {
                    font-size: ${theme.fontSize.header};
                    color: ${theme.colors.primary};
                }
                ${theme.layout.mq[0]} {
                    h1 {
                        font-size: ${theme.fontSize.header};
                    }
                }
                ${theme.layout.mq[1]} {
                    h1 {
                        font-size: ${theme.fontSize.header};
                    }
                }
            `}>
            <h1>{t('landing-page:landing-page.landing-page-start-now-header')}</h1>
            <div
                css={css`
                    padding-left: ${theme.layout.sidePadding};
                    padding-right: ${theme.layout.sidePadding};
                `}>
                <StartButtons />
            </div>
        </div>
    );
};

export default StartNowBlock;
