import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { theme } from '../../../../../utils/theming/theming';
import { css } from '@emotion/core';
import { Icon, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

const iconStyle: string = `
  width: 40px;
  height: 45px;
  @media (min-width: 1170px) {
    width: 60px;
    height: 65px;
  }`;

const TimelineBlock: React.FunctionComponent = () => {
    return (
        <section
            className={'flex justify-center items-center bg-white'}
            css={css`
                .vertical-timeline::before {
                    background-color: ${theme.colors.greyPrimaryTint};
                }
                .vertical-timeline-element-content {
                    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15), 0 0 0 rgba(16, 22, 26, 0),
                        0 0 0 rgba(16, 22, 26, 0);
                }
            `}>
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    iconStyle={{ background: 'white' }}
                    contentArrowStyle={{ borderRight: `7px solid rgba(0,0,0,0)` }}
                    icon={
                        <Icon
                            icon={IconNames.DOT}
                            intent={Intent.PRIMARY}
                            iconSize={35}
                            css={css`
                                ${iconStyle}
                            `}
                        />
                    }
                    date="October of 2019">
                    <h3 className="vertical-timeline-element-title">
                        {' '}
                        The birth of Coresine as an idea{' '}
                    </h3>
                    <p>
                        The moment the idea of a platform for all local activities, businesses and
                        products was born
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    iconStyle={{ background: 'white' }}
                    contentArrowStyle={{ borderRight: `7px solid rgba(0,0,0,0)` }}
                    icon={
                        <Icon
                            icon={IconNames.TINT}
                            intent={Intent.PRIMARY}
                            iconSize={35}
                            css={css`
                                ${iconStyle}
                            `}
                        />
                    }
                    date="December of 2019">
                    <h3 className="vertical-timeline-element-title"> The first test release </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        {' '}
                        Grocery delivery as a starting point{' '}
                    </h4>
                    <p>
                        The first release, a website with an online form where anyone could order
                        grocery delivery through us
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    iconStyle={{ background: 'white' }}
                    contentArrowStyle={{ borderRight: `7px solid rgba(0,0,0,0)` }}
                    icon={
                        <Icon
                            icon={IconNames.RECORD}
                            intent={Intent.PRIMARY}
                            iconSize={35}
                            css={css`
                                ${iconStyle}
                            `}
                        />
                    }
                    date="February of 2020">
                    <h3 className="vertical-timeline-element-title"> The second test release </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        {' '}
                        Delivery from social networks{' '}
                    </h4>
                    <p>
                        The second release, an application built on social networks as an order
                        channel
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    iconStyle={{ background: 'white' }}
                    contentArrowStyle={{ borderRight: `7px solid rgba(0,0,0,0)` }}
                    icon={
                        <Icon
                            icon={IconNames.FULL_CIRCLE}
                            intent={Intent.PRIMARY}
                            iconSize={35}
                            css={css`
                                ${iconStyle}
                            `}
                        />
                    }
                    date="July of 2020">
                    <h3 className="vertical-timeline-element-title"> Landing Page v1.0 </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        {' '}
                        Landing page as a validation{' '}
                    </h4>
                    <p>
                        The third release, a website with a landing page and values provided to our
                        users
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    iconStyle={{ background: 'white' }}
                    contentArrowStyle={{ borderRight: `7px solid rgba(0,0,0,0)` }}
                    icon={
                        <Icon
                            icon={IconNames.RING}
                            intent={Intent.PRIMARY}
                            iconSize={35}
                            css={css`
                                ${iconStyle}
                            `}
                        />
                    }
                    date="September of 2020">
                    <h3 className="vertical-timeline-element-title"> Landing Page v2.0 </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        {' '}
                        Updated landing page with information on{' '}
                    </h4>
                    <p>
                        Updated landing page with more information and teasing the concept of
                        Coresine
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    iconStyle={{ background: 'white' }}
                    contentArrowStyle={{ borderRight: `7px solid rgba(0,0,0,0)` }}
                    icon={
                        <Icon
                            icon={IconNames.FULL_CIRCLE}
                            intent={Intent.PRIMARY}
                            iconSize={35}
                            css={css`
                                ${iconStyle}
                            `}
                        />
                    }
                    date="November of 2020">
                    <h3 className="vertical-timeline-element-title"> Platform v0.4 </h3>
                    <h4 className="vertical-timeline-element-subtitle"> Basic discovery </h4>
                    <p>Discoverability of local businesses and products on the platform</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: theme.colors.primary, color: '#fff' }}
                    contentArrowStyle={{ borderRight: `7px solid ${theme.colors.primary}` }}
                    iconStyle={{ background: 'white' }}
                    icon={
                        <Icon
                            icon={IconNames.LAYER_OUTLINE}
                            intent={Intent.PRIMARY}
                            iconSize={50}
                            css={css`
                                ${iconStyle}
                            `}
                        />
                    }
                    date="January of 2021">
                    <h3 className="vertical-timeline-element-title"> Platform v1.0 </h3>
                    <p>
                        Search business on our platform, a storefront page for every business, lists
                        that can be created and shared between the users
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    iconStyle={{ background: 'white' }}
                    contentArrowStyle={{ borderRight: `7px solid rgba(0,0,0,0)` }}
                    icon={
                        <Icon
                            icon={IconNames.LAYER}
                            intent={Intent.PRIMARY}
                            iconSize={35}
                            css={css`
                                ${iconStyle}
                            `}
                        />
                    }
                    date="March of 2021">
                    <h3 className="vertical-timeline-element-title"> Platform v1.3 </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        {' '}
                        Advanced business storefront, lists and events{' '}
                    </h4>
                    <p>
                        More detailed and fuller experience for partners and customers on
                        storefront, product purchase, events and lists
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    iconStyle={{ background: 'white' }}
                    className="vertical-timeline-element--work"
                    contentArrowStyle={{ borderRight: `7px solid rgba(0,0,0,0)` }}
                    icon={
                        <Icon
                            icon={IconNames.NEW_LAYER}
                            intent={Intent.PRIMARY}
                            iconSize={35}
                            css={css`
                                ${iconStyle}
                            `}
                        />
                    }
                    date="July of 2021">
                    <h3 className="vertical-timeline-element-title"> Mobile release </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                        {' '}
                        Coresine and all its functionality on smartphones{' '}
                    </h4>
                    <p>
                        Coresine's release on iOS and Android devices with limited
                        functionality(without partner profile)
                    </p>
                </VerticalTimelineElement>
            </VerticalTimeline>
        </section>
    );
};

export default TimelineBlock;
