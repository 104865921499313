import { ReactNode } from 'react';
import { css } from '@emotion/core';
import Theme from '../../../../utils/theming/theme-type';
import { useTheme } from 'emotion-theming';
import htmlParser from 'html-react-parser';

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IOneStep {
    step: number;
    header: string;
    text: string;
    illustration: ReactNode;
    isOpposite?: boolean;
}

/**
 * The breakpoints are needed for faster, centralized and more reliable way to make responsive apps using emotion
 */
const OneStep = (props: IOneStep) => {
    const theme: Theme = useTheme();

    return (
        <section
            css={css`
                display: flex;
                height: auto;
                flex-direction: column;
                justify-content: space-evenly;
                ${theme.layout.mq[0]} {
                    flex-direction: ${props.isOpposite ? 'row-reverse;' : 'row;'};
                }
            `}>
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: ${props.isOpposite ? 'flex-start' : 'flex-end'};
                    max-width: 466px;
                    ${theme.layout.mq[1]} {
                        width: 50%;
                        text-align: ${props.isOpposite ? 'start' : 'end'};
                    }
                `}>
                <span
                    css={css`
                        display: flex;
                        align-items: center;
                        ${theme.layout.mq[1]} {
                            // flex-direction: ${props.isOpposite
                                ? 'row'
                                : 'row-reverse'} !important;
                            justify-content: ${props.isOpposite ? 'start' : 'center'};
                        }
                    `}>
                    <span
                        css={css`
                            display: block;
                            color: white;
                            background-color: ${theme.colors.warning};
                            font-size: ${theme.fontSize.default};
                            font-weight: bold;
                            text-align: center;
                            line-height: normal;
                            width: 2rem;
                            height: 2rem;
                            ${theme.layout.mq[1]} {
                                width: 2.5rem;
                                height: 2.5rem;
                                font-size: ${theme.fontSize.header};
                                line-height: 2.5rem;
                            }
                            border-radius: 50%;
                            margin-right: 1rem;
                        `}>
                        <span> {props.step} </span>
                    </span>
                    <h1
                        css={css`
                            color: ${theme.colors.secondary};
                            font-size: ${theme.fontSize.header} !important;
                            text-align: left !important;
                        `}>
                        {htmlParser(props.header)}
                    </h1>
                </span>
                <p
                    css={css`
                        font-size: ${theme.fontSize.default};
                        color: ${theme.colors.secondary};
                        max-width: 25rem;
                    `}>
                    {htmlParser(props.text)}
                </p>
            </div>
            <div
                css={css`
                    img {
                        width: 80vw;
                        max-width: 466px;
                    }
                    ${theme.layout.mq[0]} {
                        img {
                            width: 40vw;
                        }
                    }
                    ${theme.layout.mq[1]} {
                        img {
                            width: 45vw;
                        }
                    }
                `}>
                {props.illustration}
            </div>
        </section>
    );
};

export default OneStep;
