import React from 'react';
import { css } from '@emotion/core';
import Theme from '../../../../../utils/theming/theme-type';
import { useTranslation } from '../../../../../utils/i18n/i18n';
import { useTheme } from 'emotion-theming';
import MaxWidthWrapper from '../../../../../components/common/wrappers/max-width-wrapper';
import { ReactNode } from 'react';
import { theme } from '../../../../../utils/theming/theming';

interface IValueBlock {
    number: number;
    text: string | ReactNode;
}

const ValueBlock: React.FunctionComponent<IValueBlock> = ({ number, text }) => {
    return (
        <section className={'flex w-full md:w-1/3'}>
            <span
                className={
                    'block text-white text-base font-bold text-center leading-normal w-8 h-8 rounded-full mr-4 ' +
                    'sm:w-10 sm:h-10 sm:text-2xl sm:leading-10'
                }
                css={css`
                    background-color: ${theme.colors.warning};
                `}>
                {number}
            </span>
            <p className={'text-base w-4/5'}>{text}</p>
        </section>
    );
};

/**
 * The breakpoints are needed for faster, centralized and more reliable way to make responsive apps using emotion
 */
const BusinessDescriptionBlock = () => {
    const theme: Theme = useTheme();
    const { t } = useTranslation();

    return (
        <MaxWidthWrapper color={theme.colors.greyPrimaryTint}>
            <article
                className={'flex flex-col py-16 w-full'}
                css={css`
                    background-color: ${theme.colors.greyPrimaryTint};
                    margin-left: ${theme.layout.sidePadding[0]};
                    margin-right: ${theme.layout.sidePadding[0]};
                `}>
                <h1
                    className={'text-2xl mt-0 justify-center items-center md:text-4xl'}
                    css={css`
                        color: ${theme.colors.secondary};
                    `}>
                    {t('landing-page:about-us.about-us-page-business-description-header')}
                </h1>
                <div className={'flex flex-col md:flex-row justify-between leading-normal'}>
                    <ValueBlock
                        number={1}
                        text={t(
                            'landing-page:about-us.about-us-page-business-description-value-one'
                        )}
                    />
                    <ValueBlock
                        number={2}
                        text={t(
                            'landing-page:about-us.about-us-page-business-description-value-two'
                        )}
                    />
                    <ValueBlock
                        number={3}
                        text={t(
                            'landing-page:about-us.about-us-page-business-description-value-three'
                        )}
                    />
                </div>
            </article>
        </MaxWidthWrapper>
    );
};

export default BusinessDescriptionBlock;
