import React from 'react';
import BenefitsBlock from './components/for-partners/benefits-block';
import ForPartnersSteps from './components/for-partners/for-partners-steps';
import PartnersBlock from './components/for-partners/partners-block';
import { ToasterShower } from '../../../components';
import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import Router from 'next/router';

const ForPartnersPageIndex = () => {
    const learnMoreButtonHandler = (): void => {
        Router.push('q&a');
    };

    React.useEffect(() => {
        setTimeout(() => {
            ToasterShower.show({
                message:
                    'All businesses we found are added to the system automatically. ' +
                    'Verified businesses are added and managed manually. Press the button to learn more about it',
                icon: IconNames.INFO_SIGN,
                action: {
                    onClick: learnMoreButtonHandler,
                    text: 'Learn more'
                },
                timeout: 0,
                intent: Intent.WARNING
            });
        }, 1500);
    }, []);

    return (
        <main className={'flex flex-col mt-12 justify-center'}>
            <BenefitsBlock />
            <ForPartnersSteps />
            <PartnersBlock />
        </main>
    );
};

export default ForPartnersPageIndex;
