import React from 'react';
import { css } from '@emotion/core';
import { Button, Intent, Tab } from '@blueprintjs/core';
import BenefitTab from '../../common/benefit-tab';
import { useTranslation } from '../../../../../utils/i18n/i18n';
import MaxWidthWrapper from '../../../../../components/common/wrappers/max-width-wrapper';
import { ProgressiveImage } from '../../../../../components/common';
import { CustomTabs } from '../../../../../components/ui';
import Router from 'next/router';

/**
 * The breakpoints are needed for faster, centralized and more reliable way to make responsive apps using emotion
 */
const BenefitsBlockDetailed: React.FunctionComponent = () => {
    const { t } = useTranslation();

    const homePusher = (): void => {
        Router.push('home');
    };

    return (
        <MaxWidthWrapper color={'white'}>
            <article
                css={css`
                    display: flex;
                    padding-bottom: 5%;
                    background-color: white;
                    min-height: 37.5rem;
                `}>
                <CustomTabs
                    id={'business-benefits'}
                    large={false}
                    animate={true}
                    renderActiveTabPanelOnly={true}
                    className={'mt-8'}>
                    <Tab
                        id={'exploration'}
                        title={t(
                            'landing-page:for-customers.for-customers-page-sub-header-exploration'
                        )}
                        panel={
                            <BenefitTab
                                header={t(
                                    'landing-page:for-customers.for-customers-page-exploration-header'
                                )}
                                text={t(
                                    'landing-page:for-customers.for-customers-page-exploration-text-body'
                                )}
                                cta={
                                    <Button
                                        large={true}
                                        onClick={homePusher}
                                        text={t(
                                            'landing-page:for-customers.for-customers-page-exploration-button'
                                        )}
                                        intent={Intent.PRIMARY}
                                    />
                                }
                                illustration={
                                    <ProgressiveImage
                                        src={
                                            'https://res.cloudinary.com/coresine/image/upload/v1606946833/landing_page_static_files/for-customers/exploration.svg'
                                        }
                                        alt={'Shows exploration by showing parts of UI for it'}
                                        className={'ml-4 mt-12'}
                                        width={560}
                                        height={290}
                                    />
                                }
                            />
                        }
                    />
                    <Tab
                        id={'search'}
                        title={t('landing-page:for-customers.for-customers-page-sub-header-search')}
                        panel={
                            <BenefitTab
                                header={t(
                                    'landing-page:for-customers.for-customers-page-search-header'
                                )}
                                text={t(
                                    'landing-page:for-customers.for-customers-page-search-text-body'
                                )}
                                cta={
                                    <Button
                                        large={true}
                                        onClick={homePusher}
                                        text={t(
                                            'landing-page:for-customers.for-customers-page-search-button'
                                        )}
                                        intent={Intent.PRIMARY}
                                    />
                                }
                                illustration={
                                    <ProgressiveImage
                                        src={
                                            'https://res.cloudinary.com/coresine/image/upload/v1606946832/landing_page_static_files/for-customers/search.svg'
                                        }
                                        alt={
                                            'Shows search by showing parts of the UI responsible for it'
                                        }
                                        className={'ml-4 mt-8'}
                                        width={560}
                                        height={507}
                                    />
                                }
                            />
                        }
                    />
                    <Tab
                        id={'support'}
                        title={t(
                            'landing-page:for-customers.for-customers-page-sub-header-support'
                        )}
                        panel={
                            <BenefitTab
                                header={t(
                                    'landing-page:for-customers.for-customers-page-support-header'
                                )}
                                text={t(
                                    'landing-page:for-customers.for-customers-page-support-text-body'
                                )}
                                cta={
                                    <Button
                                        large={true}
                                        onClick={homePusher}
                                        text={t(
                                            'landing-page:for-customers.for-customers-page-support-button'
                                        )}
                                        intent={Intent.PRIMARY}
                                    />
                                }
                                illustration={
                                    <ProgressiveImage
                                        src={
                                            'https://res.cloudinary.com/coresine/image/upload/v1606946835/landing_page_static_files/for-customers/support.svg'
                                        }
                                        alt={
                                            'Shows support to the businesses by showing parts of the UI responsible for it'
                                        }
                                        className={'ml-8 mt-8'}
                                        width={560}
                                        height={280}
                                    />
                                }
                            />
                        }
                    />
                    <Tab
                        id={'lists'}
                        title={t('landing-page:for-customers.for-customers-page-sub-header-lists')}
                        panel={
                            <BenefitTab
                                header={t(
                                    'landing-page:for-customers.for-customers-page-lists-header'
                                )}
                                text={t(
                                    'landing-page:for-customers.for-customers-page-lists-text-body'
                                )}
                                cta={
                                    <Button
                                        large={true}
                                        onClick={homePusher}
                                        text={t(
                                            'landing-page:for-customers.for-customers-page-lists-button'
                                        )}
                                        intent={Intent.PRIMARY}
                                    />
                                }
                                illustration={
                                    <ProgressiveImage
                                        src={
                                            'https://res.cloudinary.com/coresine/image/upload/v1606946832/landing_page_static_files/for-customers/lists.svg'
                                        }
                                        alt={
                                            'Shows lists that can be used to store and share information on businesses and products'
                                        }
                                        className={'ml-4 mt-8'}
                                        width={560}
                                        height={335}
                                    />
                                }
                            />
                        }
                    />
                    <Tab
                        id={'recommendations'}
                        title={t(
                            'landing-page:for-customers.for-customers-page-sub-header-recommendations'
                        )}
                        panel={
                            <BenefitTab
                                header={t(
                                    'landing-page:for-customers.for-customers-page-recommendations-header'
                                )}
                                text={t(
                                    'landing-page:for-customers.for-customers-page-recommendations-text-body'
                                )}
                                cta={
                                    <Button
                                        large={true}
                                        onClick={homePusher}
                                        text={t(
                                            'landing-page:for-customers.for-customers-page-recommendations-button'
                                        )}
                                        intent={Intent.PRIMARY}
                                    />
                                }
                                illustration={
                                    <ProgressiveImage
                                        src={
                                            'https://res.cloudinary.com/coresine/image/upload/v1606736589/landing_page_static_files/for-customers/recommendations_.svg'
                                        }
                                        alt={'Shows recommendations as a part of the UI'}
                                        className={'ml-8 mt-16'}
                                        width={680}
                                        height={220}
                                    />
                                }
                            />
                        }
                    />
                    <Tab
                        id={'notifications'}
                        title={t(
                            'landing-page:for-customers.for-customers-page-sub-header-notifications'
                        )}
                        panel={
                            <BenefitTab
                                header={t(
                                    'landing-page:for-customers.for-customers-page-notifications-header'
                                )}
                                text={t(
                                    'landing-page:for-customers.for-customers-page-notifications-text-body'
                                )}
                                cta={
                                    <Button
                                        large={true}
                                        onClick={homePusher}
                                        text={t(
                                            'landing-page:for-customers.for-customers-page-notifications-button'
                                        )}
                                        intent={Intent.PRIMARY}
                                    />
                                }
                                illustration={
                                    <ProgressiveImage
                                        src={
                                            'https://res.cloudinary.com/coresine/image/upload/v1606946832/landing_page_static_files/for-customers/notifications.svg'
                                        }
                                        alt={'Shows notifications using UI prototype'}
                                        className={'ml-8'}
                                        width={490}
                                        height={535}
                                    />
                                }
                            />
                        }
                    />
                </CustomTabs>
            </article>
        </MaxWidthWrapper>
    );
};

export default BenefitsBlockDetailed;
