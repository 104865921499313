import { AnyAction } from 'redux';
import { State } from 'react-redux';
import * as ActionTypes from './action.types';
import produce from 'immer';

const reducer = produce((state: State = {}, action: AnyAction) => {
    const { type, payload, error } = action;
    switch (type) {
        case ActionTypes.LANDING_PAGE_SUBMIT_CONTACT_FORM_SUCCEEDED: {
            const { form } = payload;
            return { form: { submitted: true, ...form } };
        }

        case ActionTypes.LANDING_PAGE_SUBMIT_CONTACT_FORM_FAILED: {
            return state;
        }

        default: {
            return state;
        }
    }
});

export default reducer;
