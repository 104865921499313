import 'react';
import { css } from '@emotion/core';
import PromiseBlock from './components/message/promise-block';

const MessagePageIndex = () => {
    return (
        <main
            css={css`
                margin-top: 3rem;
                display: flex;
                flex-direction: column;
                background-color: white;
                display: flex;
                justify-content: center;
            `}>
            <PromiseBlock />
        </main>
    );
};

export default MessagePageIndex;
