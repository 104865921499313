import { ReactNode } from 'react';
import { css } from '@emotion/core';
import Theme from '../../../../utils/theming/theme-type';
import { useTheme } from 'emotion-theming';

import htmlParser from 'html-react-parser';

interface IBenefitTab {
    header: string;
    text: string;
    cta: ReactNode;
    illustration: ReactNode;
}

/**
 * The breakpoints are needed for faster, centralized and more reliable way to make responsive apps using emotion
 */
const BenefitTab = (props: IBenefitTab) => {
    const theme: Theme = useTheme();

    return (
        <section
            css={css`
                display: flex;
                height: 50vh;
                ${theme.layout.mq[1]} {
                    height: auto;
                }
            `}>
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                `}>
                <h1
                    css={css`
                        color: black;
                        font-size: ${theme.fontSize.display};
                        margin-bottom: 2rem;
                    `}>
                    {htmlParser(props.header)}
                </h1>
                <p
                    css={css`
                        font-size: ${theme.fontSize.header};
                        min-width: 280px;
                        margin-bottom: 2rem;
                    `}>
                    {htmlParser(props.text)}
                </p>
                {props.cta}
            </div>
            <div
                css={css`
                    img {
                        width: 0px;
                        max-width: 35rem;
                    }
                    ${theme.layout.mq[1]} {
                        img {
                            bottom: 5rem;
                            width: 50vw;
                            height: 60vh;
                        }
                    }
                `}>
                {props.illustration}
            </div>
        </section>
    );
};

export default BenefitTab;
