import React from 'react';
import { WelcomingBlock } from './common';
import TwoCTAsBlock from './components/landing-page/two-ctas-block';

const LandingPage: React.FunctionComponent = () => {
    return (
        <main className={'mt-12 bg-white flex flex-col justify-center'}>
            <WelcomingBlock />
            <TwoCTAsBlock />
        </main>
    );
};

export default LandingPage;
