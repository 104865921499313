import React from 'react';
import ForCustomersSteps from './components/for-customers/for-customers-steps';
import BenefitsBlockDetailed from './components/for-customers/benefits-block-detailed';

const ForCustomersPageIndex = () => {
    return (
        <main className={'flex flex-col mt-12 justify-center'}>
            <BenefitsBlockDetailed />
            <ForCustomersSteps />
        </main>
    );
};

export default ForCustomersPageIndex;
