import React from 'react';
import { Intent } from '@blueprintjs/core';
import Router from 'next/router';
import MaxWidthWrapper from '../../../../../components/common/wrappers/max-width-wrapper';
import { theme } from '../../../../../utils/theming/theming';
import { Button } from '@components/ui';

interface CTABlock {
    upperHeader: string;
    header: string;
    bodyText: string;
    ctaLink: string;
    align: 'left' | 'right';
}

const CTABlock: React.FunctionComponent<CTABlock> = ({
    upperHeader,
    header,
    bodyText,
    ctaLink,
    align
}) => {
    const onClickHandler = (): void => {
        Router.push(ctaLink);
    };

    return (
        <section
            className={`flex flex-col ${
                align === 'left' ? 'items-start' : 'text-right items-end'
            }`}>
            <p className={'text-sm mb-0'}> {upperHeader} </p>
            <h1 className={'text-4xl mb-8 mt-1'}> {header} </h1>
            <p className={'text-base mb-4'}> {bodyText} </p>
            <Button
                text={'Learn more'}
                className={'justify-self-start'}
                intent={Intent.PRIMARY}
                onClick={onClickHandler}
            />
        </section>
    );
};

const TwoCTAsBlock: React.FunctionComponent = () => {
    return (
        <MaxWidthWrapper color={theme.colors.greyPrimaryTint}>
            <article className={'flex flex-col justify-between py-32 sm:flex-row'}>
                <CTABlock
                    upperHeader={'for customers'}
                    header={'Your local area on your fingertips'}
                    bodyText={'Explore your local area, learn more about it, ' + 'be a part of it.'}
                    ctaLink={'/for-customers'}
                    align={'left'}
                />
                <CTABlock
                    upperHeader={'for partners'}
                    header={'Your local market simplified'}
                    bodyText={
                        'Learn  your audience, know what they  need ' +
                        'give them what they want, become their friend'
                    }
                    ctaLink={'/for-partners'}
                    align={'right'}
                />
            </article>
        </MaxWidthWrapper>
    );
};

export default TwoCTAsBlock;
