import 'react';
import { css } from '@emotion/core';
import Theme from '../../../../../utils/theming/theme-type';
import { useTranslation } from '../../../../../utils/i18n/i18n';
import { useTheme } from 'emotion-theming';
import parser from 'html-react-parser';
import MaxWidthWrapper from '../../../../../components/common/wrappers/max-width-wrapper';

/**
 * The breakpoints are needed for faster, centralized and more reliable way to make responsive apps using emotion
 */
const MissionBlock: React.FunctionComponent = () => {
    const theme: Theme = useTheme();
    const { t } = useTranslation();

    return (
        <MaxWidthWrapper color={'white'}>
            <article
                css={css`
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    padding: 10% 0;
                    background-color: white;
                    ${theme.layout.mq[0]} {
                        padding: 10% 0;
                    }
                `}>
                <h2
                    css={css`
                        color: ${theme.colors.secondary};
                        font-size: ${theme.fontSize.header};
                        text-align: center;
                        line-height: 2rem;
                        white-space: pre-line;
                        ${theme.layout.mq[0]} {
                            font-size: ${theme.fontSize.display};
                            line-height: 3.5rem;
                        }
                    `}>
                    {parser(t('landing-page:about-us.about-us-page-mission-statement'))}
                </h2>
            </article>
        </MaxWidthWrapper>
    );
};

export default MissionBlock;
