import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { FormGroup, InputGroup, TextArea } from '@blueprintjs/core';
import { useForm } from 'react-hook-form';
import { useTheme } from 'emotion-theming';
import Theme from '../../../../../utils/theming/theme-type';
import { useTranslation } from '../../../../../utils/i18n/i18n';
import * as AsyncOperations from '../../../operations';
import { IContactFormFill } from '@services/contact-service/types';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import MaxWidthWrapper from '../../../../../components/common/wrappers/max-width-wrapper';
import { ToasterShower } from '@components';
import { Button } from '@components/ui';
import { ProgressiveImage } from '../../../../../components/common';

export interface IMessage {
    name: string;
    email: string;
    phone?: string;
    text: string;
    submitted?: boolean;
    _id?: string;
}

export interface IProps {
    onFormSubmitPressed: (form: IMessage) => void;
    form?: IMessage;
    state?: any;
}

const FormBlock: React.FunctionComponent<IProps> = ({ onFormSubmitPressed, form }) => {
    const { t } = useTranslation();
    const { register, handleSubmit } = useForm();
    const theme: Theme = useTheme();

    const [isSendMessageButtonPressed, setIsSendMessageButtonPressed] = useState<boolean>(false);

    useEffect(() => {
        if (form?.submitted) {
            formSubmissionSucceeded();
        }
        if (!form?.submitted && isSendMessageButtonPressed) {
            formSubmissionFailed();
        }
    }, [form]);

    const formSubmissionSucceeded = () => {
        ToasterShower.show({ message: 'The message was sent successfully!', intent: 'success' });
    };

    const formSubmissionFailed = () => {
        ToasterShower.show({ message: 'Something went wrong', intent: 'danger' });
    };

    const messageSender = (data: IMessage) => {
        setIsSendMessageButtonPressed(true);
        onFormSubmitPressed(data);
    };

    return (
        <MaxWidthWrapper color={'white'}>
            <article
                css={css`
                    display: flex;
                    flex-direction: column;
                    background-color: white;
                    padding: 2rem 0;
                    min-height: 37.5rem;
                    ${theme.layout.mq[1]} {
                        flex-direction: row;
                        padding: 2rem 0;
                        padding-top: 0;
                    }
                `}>
                <form
                    onSubmit={handleSubmit(messageSender)}
                    css={css`
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        label {
                            font-weight: bold;
                            font-size: ${theme.fontSize.default};
                        }
                    `}>
                    <h1
                        css={css`
                            font-size: ${theme.fontSize.display};
                            color: ${theme.colors.secondary};
                            text-align: start;
                        `}>
                        {t('landing-page:contacts.contacts-page-block-header')}
                    </h1>
                    <FormGroup
                        helperText={t('landing-page:contacts.contacts-page-name-helper-text')}
                        label={t('landing-page:contacts.contacts-page-name-header')}
                        labelFor={'name'}>
                        <InputGroup
                            large={false}
                            id={'name'}
                            placeholder={t('landing-page:contacts.contacts-page-name-placeholder')}
                            inputRef={register({ required: true })}
                            name={'name'}
                            css={css`
                                input {
                                    min-width: 16rem;
                                }
                            `}
                        />
                    </FormGroup>
                    <FormGroup
                        helperText={t('landing-page:contacts.contacts-page-email-helper-text')}
                        label={t('landing-page:contacts.contacts-page-email-header')}
                        labelFor={'email'}>
                        <InputGroup
                            large={false}
                            id={'email'}
                            placeholder={t('landing-page:contacts.contacts-page-email-placeholder')}
                            inputRef={register({ required: true })}
                            name={'email'}
                            css={css`
                                input {
                                    min-width: 16rem;
                                }
                            `}
                        />
                    </FormGroup>
                    <FormGroup
                        helperText={t(
                            'landing-page:contacts.contacts-page-telephone-number-helper-text'
                        )}
                        label={t('landing-page:contacts.contacts-page-telephone-number-header')}
                        labelFor={'phone'}>
                        <InputGroup
                            large={false}
                            id={'phone'}
                            placeholder={t(
                                'landing-page:contacts.contacts-page-telephone-number-placeholder'
                            )}
                            inputRef={register({ required: true })}
                            name={'phone'}
                            css={css`
                                input {
                                    min-width: 8rem;
                                    max-width: 12rem;
                                }
                            `}
                        />
                    </FormGroup>
                    <FormGroup
                        helperText={t('landing-page:contacts.contacts-page-message-helper-text')}
                        label={t('landing-page:contacts.contacts-page-message-header')}
                        labelFor={'message'}>
                        <TextArea
                            placeholder={t(
                                'landing-page:contacts.contacts-page-message-placeholder'
                            )}
                            inputRef={register({ required: true })}
                            name={'text'}
                            id={'text'}
                            css={css`
                                min-width: 16rem;
                                min-height: 6rem;
                            `}
                        />
                    </FormGroup>
                    <Button
                        text={form?.submitted ? 'Sent' : 'Send message'}
                        type={'submit'}
                        loading={isSendMessageButtonPressed && !form?.submitted}
                        disabled={form?.submitted}
                        intent={form?.submitted ? 'success' : 'primary'}
                        large={true}
                    />
                </form>
                <ProgressiveImage
                    src={
                        'https://res.cloudinary.com/coresine/image/upload/v1606946170/landing_page_static_files/contacts-image.svg'
                    }
                    alt={'Man sends a paper plane with a mail on it'}
                    className={'ml-4 mt-24'}
                    width={560}
                    height={400}
                />
            </article>
        </MaxWidthWrapper>
    );
};

const mapStateToProps = (state) => {
    return {
        form: state.landingPageState.form
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        onFormSubmitPressed: (form: IContactFormFill) =>
            dispatch(AsyncOperations.submitContactFormAsync(form))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormBlock);
