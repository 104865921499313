import React from 'react';
import { css } from '@emotion/core';
import OneStep from '../../common/one-step';
import { useTheme } from 'emotion-theming';
import Theme from '../../../../../utils/theming/theme-type';
import { useTranslation } from '../../../../../utils/i18n/i18n';
import MaxWidthWrapper from '../../../../../components/common/wrappers/max-width-wrapper';
import { ProgressiveImage } from '../../../../../components/common';

/**
 * The breakpoints are needed for faster, centralized and more reliable way to make responsive apps using emotion
 */
const ForCustomersSteps = () => {
    const theme: Theme = useTheme();
    const { t } = useTranslation();

    return (
        <MaxWidthWrapper color={theme.colors.greyPrimaryTint}>
            <article
                css={css`
                    display: flex;
                    flex-direction: column;
                    padding-top: 2%;
                    background-color: ${theme.colors.greyPrimaryTint};
                    h1 {
                        color: black;
                        text-align: center;
                    }
                `}>
                <h1
                    css={css`
                        font-size: ${theme.fontSize.display};
                    `}>
                    {t('landing-page:for-customers.for-customers-page-steps-main-header')}
                </h1>
                <OneStep
                    step={1}
                    header={t(
                        'landing-page:for-customers.for-customers-page-steps-step-one-header'
                    )}
                    text={t(
                        'landing-page:for-customers.for-customers-page-steps-step-one-text-body'
                    )}
                    isOpposite={true}
                    illustration={
                        <ProgressiveImage
                            src={
                                'https://res.cloudinary.com/coresine/image/upload/v1606946832/landing_page_static_files/for-customers/step-one.svg'
                            }
                            width={560}
                            height={560}
                            alt={'Step 1 going to register'}
                        />
                    }
                />
                <OneStep
                    step={2}
                    header={t(
                        'landing-page:for-customers.for-customers-page-steps-step-two-header'
                    )}
                    text={t(
                        'landing-page:for-customers.for-customers-page-steps-step-two-text-body'
                    )}
                    isOpposite={false}
                    illustration={
                        <ProgressiveImage
                            src={
                                'https://res.cloudinary.com/coresine/image/upload/v1606946834/landing_page_static_files/for-customers/step-two.svg'
                            }
                            width={560}
                            height={560}
                            alt={'Step 2 - fill in the form'}
                        />
                    }
                />
                <OneStep
                    step={3}
                    header={t(
                        'landing-page:for-customers.for-customers-page-steps-step-three-header'
                    )}
                    text={t(
                        'landing-page:for-customers.for-customers-page-steps-step-three-text-body'
                    )}
                    isOpposite={true}
                    illustration={
                        <ProgressiveImage
                            src={
                                'https://res.cloudinary.com/coresine/image/upload/v1606946832/landing_page_static_files/for-customers/step-three.svg'
                            }
                            width={560}
                            height={560}
                            alt={'Step 3 - enter the code'}
                        />
                    }
                />
            </article>
        </MaxWidthWrapper>
    );
};

// @ts-ignore
export default ForCustomersSteps;
