import React from 'react';
import { css } from '@emotion/core';
import Theme from '../../../../../utils/theming/theme-type';
import { useTheme } from 'emotion-theming';
import { useTranslation } from '../../../../../utils/i18n/i18n';
import MaxWidthWrapper from '../../../../../components/common/wrappers/max-width-wrapper';
import { Button } from '@components/ui';

/**
 * The breakpoints are needed for faster, centralized and more reliable way to make responsive apps using emotion
 */
const CallToActionsBlock = () => {
    const theme: Theme = useTheme();
    const { t } = useTranslation();

    return (
        <MaxWidthWrapper color={'white'}>
            <section
                css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 10% ${theme.layout.sidePadding[0]};
                    background-color: white;
                `}>
                <h1
                    css={css`
                        font-size: ${theme.fontSize.header};
                        color: ${theme.colors.secondary};
                        margin-top: 0;
                        text-align: center;
                        ${theme.layout.mq[0]} {
                            font-size: ${theme.fontSize.display};
                        }
                    `}>
                    {t('landing-page:about-us.about-us-page-buttons-block-header')}
                </h1>
                <div
                    css={css`
                        display: flex;
                    `}>
                    <Button
                        large={true}
                        css={css`
                            margin-right: 2rem;
                        `}
                        intent={'primary'}
                        text={t('landing-page:about-us.about-us-page-buttons-block-button1')}
                    />
                    <Button
                        large={true}
                        intent={'primary'}
                        text={t('landing-page:about-us.about-us-page-buttons-block-button2')}
                        outlined={true}
                    />
                </div>
            </section>
        </MaxWidthWrapper>
    );
};

export default CallToActionsBlock;
