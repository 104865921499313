import React from 'react';
import FormBlock from './components/contacts/form-block';

const ContactUsPageIndex: React.FunctionComponent = () => {
    return (
        <main className={'flex flex-col mt-12 justify-center'}>
            <FormBlock />
        </main>
    );
};

export default ContactUsPageIndex;
