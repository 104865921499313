import React from 'react';
import { css } from '@emotion/core';
import OneStep from '../../common/one-step';
import Theme from '../../../../../utils/theming/theme-type';
import { useTheme } from 'emotion-theming';
import { useTranslation } from '../../../../../utils/i18n/i18n';
import MaxWidthWrapper from '../../../../../components/common/wrappers/max-width-wrapper';
import { ProgressiveImage } from '../../../../../components/common';

const ForPartnersSteps = () => {
    const theme: Theme = useTheme();
    const { t } = useTranslation();

    return (
        <MaxWidthWrapper color={theme.colors.greyPrimaryTint}>
            <article
                className={'flex flex-col'}
                css={css`
                    margin-left: ${theme.layout.sidePadding[0]};
                    margin-right: ${theme.layout.sidePadding[0]};
                `}>
                <h1 className={'text-4xl text-center text-black mt-12'}>
                    {t('landing-page:for-partners.for-partners-page-steps-main-header')}
                </h1>
                <OneStep
                    step={1}
                    header={t('landing-page:for-partners.for-partners-page-steps-step-one-header')}
                    text={t('landing-page:for-partners.for-partners-page-steps-step-one-text-body')}
                    isOpposite={true}
                    illustration={
                        <ProgressiveImage
                            src={
                                'https://res.cloudinary.com/coresine/image/upload/v1606946213/landing_page_static_files/for-partners/step-one.svg'
                            }
                            alt={'Step one - click the button'}
                            width={560}
                            height={400}
                        />
                    }
                />
                <OneStep
                    step={2}
                    header={t('landing-page:for-partners.for-partners-page-steps-step-two-header')}
                    text={t('landing-page:for-partners.for-partners-page-steps-step-two-text-body')}
                    illustration={
                        <ProgressiveImage
                            src={
                                'https://res.cloudinary.com/coresine/image/upload/v1606946213/landing_page_static_files/for-partners/step-two.svg'
                            }
                            alt={'Step two - fill in the form'}
                            className={'ml-12'}
                            width={560}
                            height={400}
                        />
                    }
                />
                <OneStep
                    step={3}
                    header={t(
                        'landing-page:for-partners.for-partners-page-steps-step-three-header'
                    )}
                    text={t(
                        'landing-page:for-partners.for-partners-page-steps-step-three-text-body'
                    )}
                    isOpposite={true}
                    illustration={
                        <ProgressiveImage
                            src={
                                'https://res.cloudinary.com/coresine/image/upload/v1606946213/landing_page_static_files/for-partners/step-three_mhuojg.svg'
                            }
                            alt={'Step three - wait for us to contact you'}
                            width={560}
                            height={500}
                        />
                    }
                />
            </article>
        </MaxWidthWrapper>
    );
};

export default ForPartnersSteps;
